// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-3-dprinting-js": () => import("./../src/pages/3dprinting.js" /* webpackChunkName: "component---src-pages-3-dprinting-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-concepting-js": () => import("./../src/pages/concepting.js" /* webpackChunkName: "component---src-pages-concepting-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-designing-js": () => import("./../src/pages/designing.js" /* webpackChunkName: "component---src-pages-designing-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prototyping-js": () => import("./../src/pages/prototyping.js" /* webpackChunkName: "component---src-pages-prototyping-js" */)
}

